import { NzDivider, NzText } from "@novozymes-digital/dezymer-core";
import { ReactNode } from "react";
import styled from "styled-components";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

export interface ComponentSectionProps {
  title: string;
  children: ReactNode;
  codeSnippet?: string;
}
const CodeContainer = styled.div`
  width: fit-content;
`;

const StyledSection = styled.section`
  padding: 24px;
`;

const TitleSpacer = styled.div`
  margin-bottom: 12px;
`;

export const ComponentSection = (props: ComponentSectionProps) => {
  return (
    <StyledSection>
      <TitleSpacer>
        <NzText variant="p1" tag="h2" weight="bold">
          {props.title}
        </NzText>
      </TitleSpacer>
      {props.children}
      {props.codeSnippet && (
        <CodeContainer>
          <SyntaxHighlighter language="jsx" style={darcula}>
            {props.codeSnippet}
          </SyntaxHighlighter>
        </CodeContainer>
      )}
      <NzDivider />
    </StyledSection>
  );
};
