import { NzText } from "@novozymes-digital/dezymer-core";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import gettingStarted from "./getting-started.md";

export const GettingStartedPage = () => {
  const [gettingStartedText, setGettingStartedText] = useState("");

  // Fetch Terms of Use
  useEffect(() => {
    fetch(gettingStarted)
      .then((res) => res.text())
      .then((text) => setGettingStartedText(text));
  });

  return (
    <div>
      <NzText variant="p1" tag="h1" weight="bold">
        Getting started guide
      </NzText>
      <ReactMarkdown children={gettingStartedText} />
    </div>
  );
};
