import "./App.css";
import {
  NzColorTokens,
  NzFullBody,
  NzTheme,
  NzThemeVariables,
} from "@novozymes-digital/dezymer-core";
import "@novozymes-digital/dezymer-core/dezymer-core.css";
import "@novozymes-digital/dezymer-pages/dezymer-pages.css";

import { NzPageShell, NzTopNavigation } from "@novozymes-digital/dezymer-pages";

import styled from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { CorePage } from "./pages/CorePage";
import { GettingStartedPage } from "./pages/GettingStartedPage";

const PaddedContainer = styled.div`
  padding: 64px;
`;

const router = createBrowserRouter([
  {
    path: "/",
    element: <GettingStartedPage />,
  },
  {
    path: "/core",
    element: <CorePage />,
  },
  {
    path: "/pages",
    element: <div>Add some information on how to use pages...</div>,
  },
]);

function App() {
  return (
    <>
      <NzThemeVariables />
      <NzFullBody />
      <NzTheme>
        <NzPageShell noPadding headerColor={NzColorTokens.OceanBlue[100]}>
          <NzTopNavigation
            links={[
              { title: "Getting started" },
              { title: "Core" },
              { title: "Pages" },
            ]}
            onLinkClick={(link) => {
              switch (link.title) {
                default:
                case "Getting started":
                  router.navigate("/");
                  break;
                case "Core":
                  router.navigate("/core");
                  break;
                case "Pages":
                  router.navigate("/Pages");
                  break;
              }
              return false;
            }}
          />
          <PaddedContainer>
            <RouterProvider router={router} />
          </PaddedContainer>
        </NzPageShell>
      </NzTheme>
    </>
  );
}

export default App;
