import { useState } from "react";

import {
  NzButton,
  NzTypography,
  NzDialog,
  NzText,
  NzDrawer,
} from "@novozymes-digital/dezymer-core";
import { ComponentSection } from "../components/ComponentSection";

const buttonSnippet = `\
<NzButton onClick={() => alert("Arigatou gozaimasu =✪ ᆺ ✪=")}>
  I am a button - click me !
</NzButton>`;

const dialogSnippet = `\
<NzDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
  <div style={{ width: 500, height: 300 }}>
    <NzTypography>Hello dialog</NzTypography>
  </div>
</NzDialog>
`;

const drawerSnippet = `\
<NzDrawer anchor="right" open={drawerOpen} onClose={() => setDialogOpen(false)}>
  <div style={{ width: 500, height: 300 }}>
    <NzTypography>Hello drawer</NzTypography>
  </div>
</NzDrawer>
`;

const textSnippet = `\
<NzText variant="p1">This is a p1 text</NzText>
<NzText variant="p2">This is a p2 text</NzText>
<NzText variant="p3">This is a p3 text</NzText>
<NzText variant="p4">This is a p4 text</NzText>
<NzText variant="p5">This is a p5 text</NzText>
<NzText variant="caption">This is a caption text</NzText>
`;

export const CorePage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <NzText variant="p1" tag="h1" weight="bold">
        Dezymer Core components
      </NzText>

      <ComponentSection title="NzButton" codeSnippet={buttonSnippet}>
        <NzButton onClick={() => alert("Arigatou gozaimasu =✪ ᆺ ✪=")}>
          I am a button - click me !
        </NzButton>
      </ComponentSection>

      <ComponentSection title="NzText" codeSnippet={textSnippet}>
        <NzText variant="p1" tag="p">
          This is a p1 text
        </NzText>
        <NzText variant="p2" tag="p">
          This is a p2 text
        </NzText>
        <NzText variant="p3" tag="p">
          This is a p3 text
        </NzText>
        <NzText variant="p4" tag="p">
          This is a p4 text
        </NzText>
        <NzText variant="p5" tag="p">
          This is a p5 text
        </NzText>
        <NzText variant="caption" tag="p">
          This is a caption text
        </NzText>
      </ComponentSection>

      <ComponentSection title="NzDialog" codeSnippet={dialogSnippet}>
        <button onClick={() => setDialogOpen(!dialogOpen)}>Open dialog</button>
        <NzDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div style={{ width: 500, height: 300 }}>
            <NzTypography>Hello dialog</NzTypography>
          </div>
        </NzDialog>
      </ComponentSection>

      <ComponentSection title="NzDrawer" codeSnippet={drawerSnippet}>
        <button onClick={() => setDrawerOpen(!drawerOpen)}>Open drawer</button>
        <NzDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          pressEscToClose
          clickOutsideToClose
        >
          <div style={{ width: 500, height: 300 }}>
            <NzTypography>Hello drawer</NzTypography>
          </div>
        </NzDrawer>
      </ComponentSection>
    </>
  );
};
